.menu-and-drinks-slider {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.5);
    z-index: 100;

    &::after {
        content: '';
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, var(--main_color), transparent 25%);
    }

    .mads-paper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .7;
    }

    .mads-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .3;
    }

    .mads-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .mads-btn {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: var(--main_color);
            border-radius: 50%;
            border: none;
            outline: none;
            cursor: pointer;
            z-index: 199;
            opacity: 0.5;
            transition: opacity .3s ease;

            svg {
                font-size: 1rem;
            }

            &.mads-prev {
                left: 30px;
            }

            &.mads-next {
                right: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .mads-prev {
        left: 20px !important;
    }

    .mads-next {
        right: 20px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .mads-prev {
        left: 5px !important;
    }

    .mads-next {
        right: 5px !important;
    }
}

@media only screen and (max-width: 768px) {
    .mads-prev {
        left: 0px !important;
    }

    .mads-next {
        right: 0px !important;
    }

    .mads-btn {
        width: 30px !important;
        height: 30px !important;

        svg {
            font-size: .8rem !important;
        }
    }
}