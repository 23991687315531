.our-specialties-carousel {
    max-width: 1320px;
    width: 100%;
    padding: 1rem 0;
    position: relative;

    .our-specialties-btn {
        height: 40px;
        width: 40px;
        background-color: var(--text_color);
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        border: none;
        outline: none;
        align-items: center;
        justify-content: center;
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0 3px 6px rgba(0,0,0,0.3);
        z-index: 100;

        svg {
            color: var(--main_color);
        }
    }

    .specialties-prev {
        left: -20px;
    }

    .specialties-next {
        right: -20px;
    }

    .our-specialties-carousel-slider {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: calc((100% / 3) - 12px);
        gap: 16px;
        scroll-behavior: smooth;
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;

        &.dragging {
            scroll-behavior: auto;
            scroll-snap-type: none;

            .our-specialties-card {
                cursor: grab;
                user-select: none;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    
        .our-specialties-card {
            height: 460px;
            background: rgba(255, 255, 255, 0.65);
            backdrop-filter: blur(2.5px);
            border-radius: 10px;
            scroll-snap-align: start;
            position: relative;
            overflow: hidden;
    
            .our-specialties-top {
                width: 100%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 3;
    
                img {
                    height: 200px;
                }
            }

            .our-specialties-bottom {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 3;

                h1 {
                    text-align: center;
                    text-transform: none;
                }

                p {
                    text-align: center;
                    margin: 1rem 0;
                    max-height: 160px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .our-specialties-carousel {

        .our-specialties-carousel-slider {
            grid-auto-columns: calc((100% / 2) - 9px);

            .specialties-img {
                height: 180px !important;
            }
        }

    }
}

@media only screen and (max-width: 480px){
    .our-specialties-carousel {

        .specialties-prev {
            left: 5px;
        }

        .specialties-next {
            right: 5px;
        }

        .our-specialties-carousel-slider {
            grid-auto-columns: 100%;

            .specialties-img {
                height: 180px !important;
            }
        }

    }

    .our-specialties-bottom {
        p {
            font-size: 1rem !important;
        }
    }
}