.contact-us-component {
    width: 100%;
    height: auto;
    position: relative;
    background-color: var(--background_color);

    .cuc-background {
        position: absolute;
        opacity: .2;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .cuc-pepper {
        position: absolute;
        width: 300px;
        top: 60px;
        right: 380px;
        transform: rotate(-25deg);
        opacity: .25;
    }

    .cuc-dec {
        position: absolute;
        bottom: -80px;
        right: -120px;
        height: 220px;
        z-index: 5;

        &.show {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .cuc-vine-left {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        transform: scaleX(-1) rotate(-5deg) translateX(15px);
    }

    .contact-us-component-content {
        position: relative;
        opacity: 1;
        z-index: 20;
        width: auto;
        padding: 4rem 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &.show {
            opacity: 1;
        }

        h1 {
            margin-bottom: 2rem;
        }

        .contact-us-component-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            column-gap: 15px;
            row-gap: 30px;

            .contact-us-component-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: 100%;
                max-height: 265px;

                .contact-us-component-inner {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;

                    h1 {
                        text-align: center;
                        text-transform: none;

                        &:nth-child(3) {
                            color: var(--text_color);
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        background-color: var(--hover_color);
                        margin-bottom: 1rem;

                        svg {
                            color: var(--main_color);
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .contact-us-component-content {
        padding: 4rem 80px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .contact-us-component-content {
        padding: 4rem 50px !important;
    }

    .cuc-vine-left {
        transform: scaleX(-1) rotate(-5deg) translateX(45px) !important;
    }

    .contact-us-component-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .cuci-title2 {
        font-size: 1.6rem !important;
    }

    .cuc-pepper {
        right: 260px !important;
    }
}

@media only screen and (max-width: 768px) {
    .contact-us-component-content {
        padding: 4rem 25px !important;
    }

    .cuci-title2 {
        font-size: 1.2rem !important;
    }

    .cuc-pepper {
        right: 200px !important;
        top: 180px !important;
    }
}

@media only screen and (max-width: 480px) {
    .contact-us-component-content {
        padding: 4rem 15px !important;
    }

    .contact-us-component-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .cuc-pepper {
        right: -60px !important;
        top: 300px !important;
    }

    .cuc-vine-left {
        opacity: .7 !important;
    }
}

@media only screen and (max-width: 376px) {
    .contact-us-component-content {
        padding: 4rem 10px !important;
    }
}

@media only screen and (max-width: 321px) {
    .contact-us-component-content {
        padding: 4rem 5px !important;
    }
}