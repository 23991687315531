.gallery-component-slider {
    max-width: 1320px;
    width: 100%;
    padding: 1rem 0;
    position: relative;
}

.gallery-slider-btn {
    height: 40px;
    width: 40px;
    background-color: var(--text_color);
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 3px 6px rgba(0,0,0,0.3);
    z-index: 100;

    svg {
        color: var(--main_color);
    }
}

.gallery-prev-btn {
    left: -20px;
}

.gallery-next-btn {
    right: -20px;
}

.gallery-slider-content {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: calc((100% / 4) - 12px);
    gap: 16px;
    scroll-behavior: smooth;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &.dragging {
        scroll-behavior: auto;
        scroll-snap-type: none;

        .gallery-card {
            cursor: grab;
            user-select: none;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.gallery-card {
    width: 100%;
    height: 460px;
    scroll-snap-align: start;
    position: relative;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .gallery-slider-content {
        grid-auto-columns: calc((100% / 3) - 12px);
    }
}

@media only screen and (max-width: 768px) {
    .gallery-slider-content {
        grid-auto-columns: calc((100% / 2) - 9px);
    }
}

@media only screen and (max-width: 480px) {
    .gallery-slider-content {
        grid-auto-columns: 100%;
    }

    .gallery-prev-btn {
        left: 5px;
    }

    .gallery-next-btn {
        right: 5px;
    }
}