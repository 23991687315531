.our-specialties-component {
    width: 100%;
    height: 725.6px !important;
    position: relative;
    background-color: var(--background_color);

    .osc-background {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .2;
        z-index: 1;
    }

    .osc-img {
        position: absolute;
        width: 300px;
        top: -60px;
        right: -60px;
        z-index: 10;

        &.show {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .osc-garlic {
        width: 300px;
        position: absolute;
        top: 40px;
        z-index: 2;
        opacity: .3;
        left: -40px;
        transform: rotate(30deg);
    }

    .osc-dec {
        width: 360px;
        position: absolute;
        bottom: 15px;
        left: 10px;
        transform: rotate(15deg);
        z-index: 2;

        &.show {
            opacity: 1;
            transform: translateX(0) rotate(15deg);
        }
    }

    .osc-coffee {
        position: absolute;
        bottom: -100px;
        z-index: 2;
        right: -120px;
        transform: rotate(15deg);

        &.show {
            opacity: 1;
        }
    }

    .vine-osc {
        position: absolute;
        top: -60px;
        left: -120px;
        transform: rotate(270deg) scaleX(-1);
        z-index: 5;

        &.show {
            opacity: 1;
            transform: translateX(0) rotate(270deg) scaleX(-1);
        }
    }

    .our-specialities-content {
        width: calc(100vw - 200px);
        height: auto;
        margin: 4rem 100px;
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &.show {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .our-specialities-content {
        width: calc(100vw - 160px) !important;
        margin: 4rem 80px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .our-specialities-content {
        width: calc(100vw - 100px) !important;
        margin: 4rem 50px !important;
    }

}

@media only screen and (max-width: 768px) {
   .our-specialities-content {
        width: calc(100vw - 50px) !important;
        margin: 4rem 25px !important;
    }

    .osc-img {
        width: 260px !important;
    }

    .osc-dec {
        width: 320px !important;
        bottom: 20px !important;
    }

    .osc-coffee {
        right: -160px !important;
    }
}

@media only screen and (max-width: 480px) {
    .our-specialities-content {
        width: calc(100vw - 30px) !important;
        margin: 4rem 15px !important;
    }

    .osc-img {
        width: 220px !important;
    }

    .osc-dec {
        width: 320px !important;
        bottom: 20px !important;
        left: -35px !important;
    }

    .osc-coffee {
        bottom: -80px !important;
        height: 300px;
        z-index: 2;
    }
}

@media only screen and (max-width: 376px) {
    .our-specialities-content {
        width: calc(100vw - 20px) !important;
        margin: 4rem 10px !important;
    }
}

@media only screen and (max-width: 321px) {
    .our-specialities-content {
        width: calc(100vw - 10px) !important;
        margin: 4rem 5px !important;
    }
}