.hero-component {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: var(--main_color);
    overflow: hidden;

    .hc-vine-right {
        position: absolute;
        z-index: 11;
        top: -60px;
        right: -80px;
        animation: vineRight 1.8s forwards;

        @keyframes vineRight {
            0% {
                opacity: 0;
                transform: translateX(100px);
            }
            100%{
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    .hc-vine-left {
        position: absolute;
        top: -30px;
        left: -80px;
        height: 100%;
        width: auto;
        z-index: 10;
        animation: vineLeft 1.8s forwards;

        @keyframes vineLeft {
            0% {
                opacity: 0;
                transform: translateX(-100px);
            }
            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    .hc-vine {
        position: absolute;
        width: 100%;
        height: auto;
        top: -140px;
        opacity: .3;
        animation: vineAnim 1s forwards;

        @keyframes vineAnim {
            0% {
                opacity: 0;
                transform: translateY(-100px);
            }
            100% {
                opacity: .3;
                transform: translateY(0);
            }
        }
    }

    .hc-leaves {
        position: absolute;
        top: -160px;
        left: 0;
        transform: scaleX(-1);
        height: 600px;
        z-index: 5;
        animation: leaveAnim 1.4s forwards;

        @keyframes leaveAnim {
            0% {
                opacity: 0;
                transform: scaleX(-1) translateX(100px);
            }
            100% {
                opacity: .6;
                transform: scaleX(-1) translateX(0);
            }
        }
    }

    .hc-tree {
        width: auto;
        height: 100%;
        position: absolute;
        right: -120px;
        top: 0;
        z-index: 10;
        animation: treeAnim 1.4s forwards;

        @keyframes treeAnim {
            0% {
                opacity: 0;
                transform: translateX(100px);
            }
            100% {
                opacity: .6;
                transform: translateX(0);
            }
        }
    }

    .hc-coffee {
        position: absolute;
        width: auto;
        height: 500px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: .4;
        animation: coffeeAnim .5s forwards;

        @keyframes coffeeAnim {
            0% {
                opacity: 0;
                transform: translate(-50%, -100%);
            }
            100% {
                opacity: 1;
                transform: translateY(-50%, -50%);
            }
        }
    }

    .hero-leaves {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;

        div {
            position: absolute;
            display: block;

            img {
                width: 80px;
                height: auto;
            }

            &:nth-child(1) {
                left: 30%;
                animation: animate 15s linear infinite;
                animation-delay: -7s;

                @keyframes animate {
                    0% {
                        opacity: 0;
                        top: -10px;
                        transform: translateX(20px) rotate(0deg);
                    }
                    10% {
                        opacity: 1;
                    }
                    20% {
                        transform: translateX(-20px) rotate(45deg);
                    }
                    40% {
                        transform: translateX(-20px) rotate(90deg);
                    }
                    60% {
                        transform: translateX(20px) rotate(180deg);
                    }
                    80% {
                        transform: translateX(-20px) rotate(180deg);
                    }
                    100% {
                        top: 110%;
                        transform: translateX(-20px) rotate(225deg);
                    }
                }
            }

            &:nth-child(2) {
                left: 60%;
                animation: animate 20s linear infinite;
                animation-delay: -5s;
            }

            &:nth-child(3) {
                left: 90%;
                animation: animate 20s linear infinite;
                animation-delay: 0s;
            }

            &:nth-child(4) {
                left: 75%;
                animation: animate 18s linear infinite;
                animation-delay: -10s;
            }

            &:nth-child(5) {
                left: 0%;
                animation: animate 12s linear infinite;
            }

            &:nth-child(6) {
                left: 15%;
                animation: animate 14s linear infinite;
            }
        }
    }

    .hero-leaves-2 {
        transform: scale(2) rotateY(180deg);
        filter: blur(1px);
    }

    .hero-leaves-3 {
        transform: scale(0.8) rotateX(180deg);
        filter: blur(2px);
    }

    .hero-component-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to top, #fff, transparent 70%);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;

        .hero-component-content {
            position: relative;
            width: 100%;
            margin: 0 100px;
            height: 460px;
            display: flex;

            .hcc-left {
                width: 50%;
                height: auto;
                background: rgba(255, 255, 255, 0.65);
                backdrop-filter: blur(2.5px);
                padding: 15px 10px;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    &.show {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }

                .hcc-menu-title {
                    &.show {
                        transform: translateX(0);
                        opacity: 1;
                        transition-delay: .2s;
                        text-transform: none;
                    }
                }

                .hcc-desc {
                    margin: 1rem 0;
                    font-weight: bold;
                    color: var(--title_color) !important;
                    max-height: 160px;
                    height: 160px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &.show {
                        transform: translateX(0);
                        opacity: 1;
                        transition-delay: .4s;
                    }
                }

                .hcc-media {
                    display: flex;
                    align-items: center;

                    &.show {
                        opacity: 1;
                        transform: translateX(0);
                        transition-delay: .6s;
                    }

                    .dark-hero-menu {
                        background-color: var(--hover_color);
                        color: var(--main_color) !important;

                        span {
                            &:last-child p {
                                color: var(--main_color) !important;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                        color: var(--text_color);
                        margin-right: 30px !important;
                        background-color: var(--main_color);
                        border-radius: 10px !important;

                        &:last-child {
                            margin-right: 0 !important;
                        }

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 15px;
                            box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.35);
                            border-radius: 10px !important;

                            &:last-child p {
                                margin-left: 10px;
                                font-weight: bold;
                            }

                            svg {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }

        .hcc-right {
            width: 50%;
            height: auto;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 460px;
            }

            .hero-current-menu {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 1;
                transition: opacity 1s, transform 1s;
                transform: translateX(0);
            }

            .hero-hidden-menu {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 1s, transform 1s;
                transform: translateX(100%);
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .hero-component-content {
        margin: 0 80px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .hero-component-content {
        margin: 0 50px !important;
        height: 360px !important;
    }

    .hcc-text {
        font-size: 1rem !important;
    }

    .hcc-right {
        img {
            height: 360px !important;
        }
    }

    .hc-leaves {
        top: -240px !important;
    }
}

@media only screen and (max-width: 768px) {
    .hero-component-content {
        margin: 0 25px !important;
        height: 320px !important;
    }

    .hcc-right {
         img {
            height: 320px !important;
         }
    }

    .hc-leaves {
        height: 420px !important;
        top: -120px !important;
    }

    .hcc-text {
        font-size: .9rem !important;
    }

    .hero-leaves {
        div {
            img {
                width: 60px !important;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .hero-component-content {
        margin: 0 15px !important;
    }

    .hc-vine-right {
        width: 380px !important;
        height: auto;
        z-index: 10 !important;
    }

    .hcc-left {
        z-index: 20 !important;
        width: 100% !important;
        position: absolute;
        padding: 0 !important;
        height: 320px !important;
        background: rgba(255, 255, 255, 0.6) !important;
        backdrop-filter: blur(1px) !important;
        align-items: center;

        h2 {
            padding: 0 10px !important;
            padding-top: 10px !important;
            color: var(--hover_color) !important;
            text-align: center;
        }

        h1 {
            padding: 0 10px !important;
            text-align: center;
        }

        .hcc-media {
            padding: 0 10px !important;
            padding-bottom: 10px !important;
        }

        .hcc-desc {
            text-align: center !important;
        }
    }

    .hc-coffee {
        position: absolute;
        width: auto;
        height: 400px !important;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: .4;
    }

    .hcc-right {
        position: relative;
        width: 100% !important;
        z-index: 9;
    }

    .hc-leaves {
        height: 320px !important;
        top: -30px !important;
        left: -80px !important;
        z-index: 10 !important;
    }

    .hc-tree {
        right: -145px !important;
        z-index: 5 !important;
    }
}

@media only screen and (max-width: 376px) {
    .hero-component-content {
        margin: 0 10px !important;
    }

    .hc-vine-right {
        width: 320px !important;
        height: auto;
        z-index: 10 !important;
    }
}

@media only screen and (max-width: 321px) {
    .hero-component-content {
        margin: 0 5px !important;
    }

    .hcc-left {
        height: 280px !important;

        h1 {
            font-size: 1.6rem !important;
        }
    }

    .hcc-right {
        height: 280px !important;

        img {
            height: 280px !important;
        }
    }
}