.menu-and-drinks-component {
    position: relative;
    width: 100%;
    padding: 4rem 0;
    z-index: 999;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, var(--main_color), transparent 15%), linear-gradient(to bottom, var(--main_color), transparent 15%);
        z-index: 5;
    }

    .madc-old-paper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .7;
        z-index: 1;
    }

    .madc-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .4;
        z-index: 1;
    }

    .madc-vine {
        position: absolute;
        top: -100px;
        right: -75px;
        transform: scaleX(-1);
        height: 500px;
        z-index: 20;

        &.show {
            opacity: 1;
        }
    }

    .madc-decoration {
        position: absolute;
        top: -280px;
        z-index: 10;
        left: -100px;
        height: 500px;
        transform: rotate(15deg);

        &.show {
            opacity: 1;
            transform: translateX(0) rotate(15deg);
        }
    }

    .madc-vine-dec-right {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        right: -540px;

        &.show {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .madc-vine-dec-left {
        position: absolute;
        top: 0;
        left: -540px;
        width: 100%;
        height: 100%;
        z-index: 2;
        transform: scaleX(-1);

        &.show {
            opacity: 1;
            transform: translateX(0) scaleX(-1);
        }
    }

    .madc-leaves {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        pointer-events: none;

        div {
            position: absolute;
            display: block;

            img {
                width: 80px;
                height: auto;
            }

            &:nth-child(1) {
                left: 30%;
                animation: animate 53s linear infinite;
                animation-delay: -7s;

                @keyframes animate {
                    0% {
                        opacity: 0;
                        top: -10px;
                        transform: translateX(20px) rotate(0deg);
                    }
                    10% {
                        opacity: 1;
                    }
                    20% {
                        transform: translateX(-20px) rotate(45deg);
                    }
                    40% {
                        transform: translateX(-20px) rotate(90deg);
                    }
                    60% {
                        transform: translateX(20px) rotate(180deg);
                    }
                    80% {
                        transform: translateX(-20px) rotate(180deg);
                    }
                    100% {
                        top: 110%;
                        transform: translateX(-20px) rotate(225deg);
                    }
                }
            }

            &:nth-child(2) {
                left: 60%;
                animation: animate 58s linear infinite;
                animation-delay: -5s;
            }

            &:nth-child(3) {
                left: 90%;
                animation: animate 58s linear infinite;
                animation-delay: 0s;
            }

            &:nth-child(4) {
                left: 75%;
                animation: animate 56s linear infinite;
                animation-delay: -10s;
            }

            &:nth-child(5) {
                left: 0%;
                animation: animate 50s linear infinite;
            }

            &:nth-child(6) {
                left: 15%;
                animation: animate 52s linear infinite;
            }
        }
    }

    .madc-leaves-1 {
        transform: scale(1.4) rotateY(180deg);
        filter: blur(1px);
    }

    .madc-leaves-2 {
        transform: scale(0.8) rotateX(180deg);
        filter: blur(2px);
    }

    .madc-content {
        width: auto;
        height: 100%;
        margin: 0 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 20;

        .madc-header {
            max-width: 600px;
            height: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: auto;
            width: 100%;
            column-gap: 15px;
            margin-bottom: 4rem;

            .madc-header-category {
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.35);
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                z-index: 9999;
                background-color: var(--main_color);

                .madc-hc {
                    text-decoration: none;
                    font-size: 1.5rem;
                    color: var(--text_color);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding: 10px 15px;
                    cursor: pointer;
                }
            }

            .madc-header-active {
                background-color: var(--background_color);

                .madc-hc {
                    color: var(--main_color);
                }
            }
        }

        .madc-items {
            width: 100%;
            max-width: 800px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &.show {
                opacity: 1;
            }

            .madc-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;

                h1 {
                    margin: 1.5rem 0;
                    text-align: center;
                }

                .madc-mad {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;

                    .single-mad {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        h3 {
                            margin-bottom: 10px;
                            color: var(--hover_color);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .madc-content {
        margin: 0 80px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .madc-content {
        margin: 0 50px !important;
    }

    .madc-decoration {
        top: -240px !important;
        height: 400px !important;
    }

    .madc-vine-dec-right {
        right: -480px !important;
    }

    .madc-vine-dec-left {
        left: -480px !important;
    }
}

@media only screen and (max-width: 768px) {
    .madc-content {
        margin: 0 25px !important;
    }

    .madc-vine {
        top: -80px !important;
        height: 400px !important;
    }

    .madc-decoration {
        top: -220px !important;
        height: 360px !important;
    }

    .madc-vine-dec-right {
        right: -320px !important;
        opacity: .6 !important;
    }

    .madc-vine-dec-left {
        left: -320px !important;
        opacity: .6 !important;
    }
}

@media only screen and (max-width: 480px) {
    .madc-content {
        margin: 0 15px !important;
    }

    .single-mad {
        h3 {
            font-size: .9rem !important;

            &:nth-child(2) {
                font-size: .8rem !important;
            }
        }
    }

    .madc-vine {
        top: -80px !important;
        height: 400px !important;
    }

    .madc-decoration {
        top: -160px !important;
        height: 280px !important;
    }

    .madc-vine-dec-right {
        right: -380px !important;
        width: 200% !important;
    }

    .madc-vine-dec-left {
        left: -380px !important;
        width: 200% !important;
    }
}

@media only screen and (max-width: 376px) {
    .madc-content {
        margin: 0 10px !important;
    }

    .madc-vine-dec-right {
        right: -340px !important;
        width: 200% !important;
    }

    .madc-vine-dec-left {
        left: -340px !important;
        width: 200% !important;
    }
}

@media only screen and (max-width: 321px) {
    .madc-content {
        margin: 0 5px !important;
    }

    .madc-vine-dec-right {
        right: -420px !important;
        width: 250% !important;
    }

    .madc-vine-dec-left {
        left: -420px !important;
        width: 250% !important;
    }
}