.toggle-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        position: relative;

        img {
            width: 55px;
            height: 55px;
            object-fit: cover;
        }

        .toggle-btn-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(0,0,0,0.25);
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
                color: var(--main_color);
            }
        }
    }
}